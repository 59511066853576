<!--
 * @Author: inwen6
 * @Date: 2020-12-07 15:42:34
 * @LastEditTime: 2020-12-21 11:03:19
 * @LastEditors: your name
 * @Description: 产品页面
 * @FilePath: /hheiqngting-gw/src/views/product.vue
-->
<template>
  <div class="productBox" id="productBox">
    <div class="video">
      <el-row class="videosBox">
        <el-col :xs="0" :sm="24" :md="24" :lg="24" class="videosBox">
          <video
            autoplay="autoplay"
            muted
            loop
            :src="bannerData.bannerUrl"
            v-if="bannerData.type == 2"
          ></video>

          <el-image
            class="imgbox2"
            :src="bannerData.bannerUrl"
            fit="cover"
            v-if="bannerData.type == 1"
          />
        </el-col>
      </el-row>
    </div>
    <el-row class="productList">
      <div class="colBox flex">
        <div
          class="li flex a-swing hvr-sweep-to-right"
          v-for="(item, index) in productList"
          :key="index"
          @click="indexs(item, index)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="product">
        <!-- 产品大图展示 -->
        <div class="title_img_box">
          <el-image
            class="img"
            fit="cover"
            v-if="productList"
            :src="productList[idx].bgImgUrl"
          ></el-image>
        </div>
        <!-- 产品列表 -->
        <div class="lunbo flex">
          <div v-animation="'test111'" class="left_box_img flex">
            <div class="list">
              <div class="li" v-for="(item, index) in imgArr" :key="index">
                <el-image
                  class="img"
                  @mousemove="mousemove(item)"
                  :src="item"
                ></el-image>
              </div>
            </div>
            <div class="photo">
              <el-image
                class="img"
                :src="rightImageUrl || imgArr[0]"
              ></el-image>
            </div>
          </div>
          <div v-animation="'test222'" class="right_box_img">
            <!-- <el-image class="img" :src="rightImageUrl"></el-image> -->
            <div class="title_name">产品名称</div>
            <div class="icon_box">
              <div class="icon_li flex">
                <div class="icon">
                  <el-image
                    class="icon-img"
                    :src="productBGArr[idx][0]"
                    fit="contain"
                  ></el-image>
                </div>

                <div class="text">{{ productTitleArr[idx][0] }}</div>
              </div>
              <div class="icon_li flex">
                <div class="icon">
                  <el-image
                    class="icon-img"
                    :src="productBGArr[idx][1]"
                    fit="contain"
                  ></el-image>
                </div>

                <div class="text">{{ productTitleArr[idx][1] }}</div>
              </div>
              <div class="icon_li flex">
                <div class="icon">
                  <el-image
                    class="icon-img"
                    :src="productBGArr[idx][2]"
                    fit="contain"
                  ></el-image>
                </div>
                <div class="text">{{ productTitleArr[idx][2] }}</div>
              </div>
              <div class="icon_li flex">
                <div class="icon">
                  <el-image
                    class="icon-img"
                    :src="productBGArr[idx][3]"
                    fit="contain"
                  ></el-image>
                </div>

                <div class="text">{{ productTitleArr[idx][3] }}</div>
              </div>
              <!-- <el-image
                fit="cover"
                class="imgimg"
                :src="productBG[idx]"
                alt=""
              /> -->
            </div>
            <div class="detail_text">
              产品型号：{{ productList && productList[idx].model }}
            </div>
            <div class="detail_text">
              尺寸：{{ productList && productList[idx].size }}
            </div>
            <div class="detail_text">
              输入：{{ productList && productList[idx].input }}
            </div>
            <div class="detail_text">
              输出：{{ productList && productList[idx].output }}
            </div>
            <div class="detail_text">
              联网方式：{{ productList && productList[idx].networking }}
            </div>
          </div>
        </div>
      </div>
    </el-row>
    <div class="footer">
      <foo></foo>
    </div>
  </div>
</template>
<script>
import foo from "../components/HelloWorld";
import { product, banner } from "@/api/api_list";
function debounce(fn, wait) {
  var timer = null;
  return function () {
    var context = this;
    var args = arguments;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, wait);
  };
}
export default {
  components: { foo },
  data() {
    return {
      imgArr: [],
      rightImageUrl: "",
      bannerData: "",
      productList: "",
      idx: 0,

      productBGArr: [
        [
          require("../assets/product/x01.png"),
          require("../assets/product/x02.png"),
          require("../assets/product/x03.png"),
          require("../assets/product/x04.png"),
        ],
        [
          require("../assets/product/a01.png"),
          require("../assets/product/a02.png"),
          require("../assets/product/a03.png"),
          require("../assets/product/a04.png"),
        ],
        [
          require("../assets/product/b01.png"),
          require("../assets/product/b02.png"),
          require("../assets/product/b03.png"),
          require("../assets/product/b04.png"),
        ],
        [
          require("../assets/product/c01.png"),
          require("../assets/product/c02.png"),
          require("../assets/product/c03.png"),
          require("../assets/product/c04.png"),
        ],
      ],
      productTitleArr: [
        ["节能环保", "4G/WIFI通讯", "任意叠加", "安全稳定"],
        ["占地面积小", "安全稳定", "4G/WIFI通讯", "40口仓位"],
        ["即摆即用", "双重收益", "可定制画面", "48口仓位"],
        ["节能环保", "4G/WIFI通讯", "小巧多变", "安全稳定"],
      ],

      productBG: [
        require("../assets/product/6hao.png"),
        require("../assets/product/12hao.png"),
        require("../assets/product/24hao.png"),
        require("../assets/product/48hao.png"),
      ],
    };
  },
  created() {
    banner({ location: 2 }).then((res) => {
      if (res.code == 200) {
        this.bannerData = res.data;
      }
    });
    product({ pageNum: 1, pageSize: 10 }).then((res) => {
      if (res.code == 200) {
        this.productList = res.data.list;
        this.indexs(res.data.list[0], 0);
      }
    });
  },
  methods: {
    filtersData(data) {
      if (!data) return;
      return data.split(",") || [];
    },
    indexs(item, index) {
      this.idx = index;
      this.imgArr = this.filtersData(item.detailImgsUrl);
      this.rightImageUrl = this.imgArr[0];
    },
    mousemove: debounce(function (e) {
      this.rightImageUrl = e;
    }, 80),
  },
};
</script>
<style lang="scss" scoped>
body {
  background: black;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
}
.productBox {
  width: 100%;
  height: 100%;
  background: black;
  .video {
    width: 100%;
    height: 500px;
    .videosBox {
      width: 100%;
      height: 100%;
      video,
      .imgbox2 {
        width: 100%;
        height: 100%;
      }
    }
  }
  .productList {
    width: 100%;
  }
  .colBox {
    width: 100%;
    height: 100px;
    background: white;
    .li {
      width: 200px;
      height: 44px;
      font-size: 12px;
      color: #999;
      border: 1px solid rgb(230, 230, 230);
      margin-right: 10px;
      animation-fill-mode: none;
      cursor: pointer;
    }
    .li:hover {
      color: #474747;
      transform: scale(1.1);
    }
  }
  // 产品
  .product {
    width: 100%;
    // height: 500px;
    /* background: pink; */
    .title_img_box {
      margin: 0 auto;
      width: 100%;
      height: 350px;
      background: white;
      .img {
        width: 100%;
        height: 100%;
      }
    }
    .lunbo {
      width: 100%;
      height: 500px;
      background: white;
      box-sizing: border-box;
      padding: 20px;
      .left_box_img {
        min-width: 650px;
        width: 60%;
        height: 100%;
        overflow: hidden;
        box-sizing: border-box;
        .list {
          width: 100px;
          // max-width: 100px;
          height: 100%;
          background: white;
          box-sizing: border-box;
          overflow-y: scroll;
          scrollbar-color: transparent transparent;
          .li {
            width: 80px;
            height: 80px;
            // background: #999;
            margin: 10px 0px 0px 10px;
            float: left;
            cursor: pointer;
            .img {
              width: 100%;
              height: 100%;
            }
            .img:hover {
              transform: scale(1.1);
            }
          }
        }
        .photo {
          // height: 100%;
          width: 500px;
          height: 100%;
          // background: #121212;
          background: black;
          margin-left: 10px;
          .img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .test111 {
        animation: donghua 0.5s;
      }
      .test222 {
        animation: donghua2 0.5s;
      }
      @keyframes donghua {
        from {
          transform: translateX(-300px);
        }
        to {
          transform: translateX(0px);
        }
      }
      @keyframes donghua2 {
        from {
          transform: translateX(300px);
        }
        to {
          transform: translateX(0px);
        }
      }
      .right_box_img {
        width: 40%;
        height: 100%;
        // background: orange;
        box-sizing: border-box;
        padding: 10px;
        color: #474747;
        .title_name {
          font-size: 16px;
          font-weight: 500;
          color: black;
          opacity: 0.6;
        }
        .icon_box {
          // width: 400px;
          height: 100px;
          //   background: burlywood;
          display: flex;
          flex-direction: row;
          box-sizing: border-box;
          justify-content: flex-start;
          .imgimg {
            width: 260px;
          }
          .icon_li {
            margin-top: 15px;
            margin-bottom: 15px;
            width: 80px;
            height: 80px;
            // background: chocolate;
            flex-direction: column;
            .icon {
              width: 50px;
              height: 50px;
              border: 0.5px solid rgb(230, 230, 230);
              margin-bottom: 12px;
              border-radius: 25px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;

              .icon-img {
                width: 50px;
                height: 20px;
              }
            }

            .text {
              font-size: 10px;
            }
          }
        }
        .detail_text {
          width: 100%;
          height: 20px;
          font-size: 12px;
          margin-top: 10px;
          color: black;
          opacity: 0.6;
        }
      }
    }
  }
  .footer {
    width: 100%;
    height: 100%;
    background: black;
    overflow: hidden;
  }
}
@media screen and (max-width: 768px) {
  .colBox {
    display: none;
  }
}
</style>
